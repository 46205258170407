
.v-binder-follower-container {
  z-index: 3000 !important;
}
.dynamic-down-cascader-container {
  position: fixed;
  z-index: 3000;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}
