.dynamic-cascader[data-v-3ad4c273] {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 24px;
  padding: 0 7px;
  border: 1px solid #b9b9b9;
}
.dynamic-cascader .dynamic-box[data-v-3ad4c273] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.dynamic-cascader input.dynamic-cascader-input[data-v-3ad4c273] {
  display: block;
  flex: 1;
  color: #606266;
  outline: unset;
}
.dynamic-cascader .clear-data-icon[data-v-3ad4c273] {
  position: relative;
  z-index: 5;
}
[data-v-3ad4c273] .el-cascader {
  display: block !important;
  margin-top: -1px;
  margin-left: -1px;
}